import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import allowed from "../../images/cancel.png";
import forbidden from "../../images/cancel-2.png";
import PrivacyHeader from "../components/PrivacyHeader";
import axios from "axios";
class CancelCard extends Component {
  state = {
    isValid: true,
    title: "Cancel card order",
    message: "Are you sure you want to cancel your card order?",
    isChecked: false,
  };

  componentDidMount() {
    const data = new URL(window.location).searchParams.get("data");

    if (data.length > 0) {
      // const endPoint = `https://api.junio.in/api/register/cancelorder?data=${data}`;
      const endPoint = "";
      axios
        .get(endPoint)
        .then((res) => {})
        .catch((err) => {});
    }
  }
  cancelCard = () => {};
  render() {
    return (
      <React.Fragment>
        <PrivacyHeader />
        <Container fluid className="cancel-card text-start c-pad">
          <div>
            {this.state.isValid ? (
              <img src={allowed} />
            ) : (
              <img src={forbidden} />
            )}
          </div>
          <div className="cancel-t">{this.state.title}</div>
          <div className="cancel-m">{this.state.message}</div>
          {this.state.isValid ? (
            <React.Fragment>
              <Row className="c-h2 d-flex cancel-check">
                <Col className="col-auto pe-3 ">
                  <Form.Check.Input
                    onChange={(e) => {
                      this.setState({ isChecked: e.target.checked });
                    }}
                  />
                </Col>
                <Col>
                  <div className="c-h2">
                    I understand I will get 2% cashback instead of 5% if I
                    cancel the card order
                  </div>
                </Col>
              </Row>
              <div>
                <Button disabled={!this.state.isChecked}>Cancel Card</Button>
              </div>
            </React.Fragment>
          ) : (
            <div className="c-h2">
              For help & support please mail us on
              <br />
              <b>junio@support.in</b>
            </div>
          )}
        </Container>
      </React.Fragment>
    );
  }
}
export default CancelCard;
